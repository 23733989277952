import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/fuelling/Development/sappenin/ilpv4-dev-website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "interledger-packets"
    }}>{`Interledger Packets`}</h1>
    <p>{`ILP uses an `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/blob/master/0030-notes-on-oer-encoding/0030-notes-on-oer-encoding.md"
      }}>{`OER-encoded`}</a>{`
binary packet framework to ensure the most compact representation of money while in transit.`}</p>
    <p>{`There are three types of packet: `}<inlineCode parentName="p">{`PREPARE`}</inlineCode>{`, `}<inlineCode parentName="p">{`FULFILL`}</inlineCode>{`, and `}<inlineCode parentName="p">{`REJECT`}</inlineCode>{`.`}</p>
    <p>{`You can discover the details of each one in the ILPv4 RFC under `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/blob/master/0027-interledger-protocol-4/0027-interledger-protocol-4.md#packet-format"
      }}>{`Packet Format`}</a>{`, but this page details concrete representations of each.`}</p>
    <h2 {...{
      "id": "prepare-packet"
    }}>{`Prepare Packet`}</h2>
    <p>{`This is an actual binary `}<inlineCode parentName="p">{`PREPARE`}</inlineCode>{` packet, hex-encoded:`}</p>
    <pre><code parentName="pre" {...{}}>{`0C430000000000000001323032303035313631343137353232303566687AADF862BD776C8FC18B8E9F8E20089714856EE233B3902A591D0D5F292508746573742E626F6200
`}</code></pre>
    <p>{`It contains the following information:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Packet Type`}</strong>{`: `}<inlineCode parentName="li">{`12`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`amount`}</strong>{`: `}<inlineCode parentName="li">{`1`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`expiresAt`}</strong>{`: `}<inlineCode parentName="li">{`2020-05-16T14:19:40.768Z`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`executionCondition`}</strong>{`: `}<inlineCode parentName="li">{`Zmh6rfhivXdsj8GLjp+OIAiXFIVu4jOzkCpZHQ1fKSU=`}</inlineCode>{` (ASN1.OER, Base64-encoded)`}</li>
      <li parentName="ul"><strong parentName="li">{`destination`}</strong>{`: `}<inlineCode parentName="li">{`test.alice`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`data`}</strong>{`: `}<inlineCode parentName="li">{`[empty]`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "fulfill-packet"
    }}>{`Fulfill Packet`}</h2>
    <p>{`This is the corresponding binary `}<inlineCode parentName="p">{`FULFILL`}</inlineCode>{` packet, hex-encoded:`}</p>
    <pre><code parentName="pre" {...{}}>{`0D21000000000000000000000000000000000000000000000000000000000000000000
`}</code></pre>
    <p>{`It contains the following information:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Packet Type`}</strong>{`: `}<inlineCode parentName="li">{`13`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`fulfillment`}</strong>{`: `}<inlineCode parentName="li">{`AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=`}</inlineCode>{` (ASN1.OER, Base64-encoded)`}</li>
      <li parentName="ul"><strong parentName="li">{`data`}</strong>{`: `}<inlineCode parentName="li">{`[empty]`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "reject-packet"
    }}>{`Reject Packet`}</h2>
    <p>{`This is the corresponding binary `}<inlineCode parentName="p">{`REJECT`}</inlineCode>{` packet, hex-encoded:`}</p>
    <pre><code parentName="pre" {...{}}>{`0E114630320B746573742E636F6E6E69650000
`}</code></pre>
    <p>{`It contains the following information:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Packet Type`}</strong>{`: `}<inlineCode parentName="li">{`14`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Message`}</strong>{`: `}<inlineCode parentName="li">{`[empty]`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Error Code`}</strong>{`: `}<inlineCode parentName="li">{`F02 Rejeced`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Triggered By`}</strong>{`: `}<inlineCode parentName="li">{`test.connie`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`data`}</strong>{`: `}<inlineCode parentName="li">{`[empty]`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      